body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.line-animation {
  position: absolute;
  bottom: -4px;
  left: 0;
  height: 2px;
  background-color: #f0ec0c;
  transition: width 0.3s ease;
  width: 100%;
}

.line-animation:hover {
  background-color: #fa0304;
}

.arrow {
  width: 50px;
  display: inline;
  margin-bottom: 2px;
  margin-top: 0;
  margin-left: 5px;
}

.arrow:hover {
  width: 53px;
}

.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none;
}

.goog-te-combo {
  height: 29px;
  margin-top: 17px;
  border-radius: 5px;
  width: 115px;
}

.goog-te-gadget {
  color: #1e3a8a;
}

.goog-te-gadget .goog-te-combo {
  margin-top: 20px;
}

@media (max-width: 640px) {
  .goog-te-combo {
    width: 80px;
    margin-top: 25px;
    height: 24px;
  }

  .goog-te-gadget .goog-te-combo {
    margin-top: 20px;
  }
}

/* .spinner {
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} */


/* @media only screen and (max-width: 600px) {
  .media-query {
    display: none;
  }
} */

.mainLogo {
  border: 2px solid rgb(90, 183, 254);
  margin-top: 10px;
  border-top: none;
  height: 85px;
  width: 85px;
  background: #1e3a8a;
  padding: 5px;
  border-bottom-right-radius: 10%;
  border-bottom-left-radius: 10%;
}
